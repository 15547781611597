<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Publish Project</h2>
    <button type="button" (click)="close()" class="close-button no-style icon-button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<div *ngIf="isServices == false">
    <div class="warning_area">
        <mat-icon svgIcon="warning"></mat-icon>
        <p class="error">Please add SEVICES before you can download your project!</p>
    </div>

    <p>You can add services by following steps:</p>
    <ol class="list_style">
        <li>Click on the Variable button from the left menu.</li>
        <li>Scroll down and find the services text box area.</li>
        <li>Enter services separated by new line.</li>
        <li>Save your project and come back to download your project</li>
    </ol>
    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="button cancel cancel-button" trans>Cancel</button>
    </div>
</div>
<div *ngIf="isLocation == false">
    <div class="warning_area">
        <mat-icon svgIcon="warning"></mat-icon>
        <p class="error">Please add LOCATIONS before you can download your project!</p>
    </div>
    <p>You can add services by following steps:</p>
    <ol class="list_style">
        <li>Click on the Variable button from the left menu.</li>
        <li>Click the "Change Location" button and a popup will appear.</li>
        <li>Follow the steps in the location popup click change button</li>
        <li>Save your project and come back to download your project</li>
    </ol>
    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="button cancel cancel-button" trans>Cancel</button>
    </div>
</div>
<form (ngSubmit)="confirm()" ngNativeValidate *ngIf="isLocation && isServices">

    <div class="project-url">
        <a [href]="getProjectUrl()" target="_blank">{{getProjectUrl(true)}}</a>
        <mat-slide-toggle color="accent" name="ssl" [checked]="!!data.project.published" [disabled]="loading"
            (change)="toggleProjectState($event)" trans>Public</mat-slide-toggle>
    </div>

    <div class="mat-dialog-content many-inputs" mat-dialog-content>
        <ng-container *ngIf="settings.get('publish.allow_credential_change')">
            <div class="input-container">
                <label for="host" trans>Website URL</label>
                <input type="text" name="domain" id="domain" [(ngModel)]="ftpDetails.domain" required>
                <p class="error" *ngIf="errors.host">{{errors.host}}</p>
            </div>
            <div class="input-container">
                <label for="host" trans>Host</label>
                <input type="text" name="host" id="host" [(ngModel)]="ftpDetails.host" required>
                <p class="error" *ngIf="errors.host">{{errors.host}}</p>
            </div>

            <div class="input-container">
                <label for="username" trans>Username</label>
                <input type="text" name="username" id="username" [(ngModel)]="ftpDetails.username" required>
                <p class="error" *ngIf="errors.username">{{errors.username}}</p>
            </div>

            <div class="input-container">
                <label for="password" trans>Password</label>
                <input type="password" name="password" id="password" [(ngModel)]="ftpDetails.password" required>
                <p class="error" *ngIf="errors.password">{{errors.password}}</p>
            </div>

            <div class="input-container">
                <label for="directory" trans>Directory</label>
                <input type="text" name="directory" id="directory" [(ngModel)]="ftpDetails.directory"
                    placeholder="(Optional)" trans-placeholder>
                <p class="error" *ngIf="errors.directory">{{errors.directory}}</p>
            </div>

            <div class="inline-inputs">
                <div class="input-container">
                    <label for="port" trans>Port</label>
                    <input type="number" name="port" id="port" [(ngModel)]="ftpDetails.port" required>
                    <p class="error" *ngIf="errors.port">{{errors.port}}</p>
                </div>

                <mat-slide-toggle color="accent" name="ssl" [(ngModel)]="ftpDetails.ssl">SSL</mat-slide-toggle>
            </div>
        </ng-container>
    </div>

    <mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>

    <div class="error no-input-error" *ngIf="errors['*']">{{errors['*']}}</div>

    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="button cancel cancel-button" trans>Cancel</button>
        <button type="submit" mat-raised-button color="accent" class="button primary submit-button" [disabled]="loading"
            trans>Publish</button>
    </div>
</form>