<form class="auth-page" id="auth-page" (ngSubmit)="sendPasswordResetLink()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
    <div class="auth-panel">
        <loading-indicator class="overlay" [isVisible]="loading$ | async"></loading-indicator>

        <a class="logo" [routerLink]="['/']"><img [src]="settings.get('branding.logo_dark')" alt="logo"></a>

        <div class="general-error" *ngIf="errors.general">{{errors.general}}</div>

        <div class="page-header" trans>Enter your email address below and we will send you a link to reset or create your password.</div>

        <div class="page-content">
            <div class="input-container">
                <label for="email" class="hidden"></label>
                <input type="email" formControlName="email" id="email" required placeholder="Email" trans-placeholder>
                <p class="error email-error" *ngIf="errors.email">{{errors.email}}</p>
            </div>

            <div class="back-and-reset-row">
                <a class="button cancel back-button" mat-button [routerLink]="['/login']" trans>Back</a>
                <button class="button primary submit-button" mat-raised-button color="accent" type="submit" trans>Send</button>
            </div>
        </div>
    </div>
</form>
