import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from 'common/core/config/settings.service';
import { Modal } from 'common/core/ui/dialogs/modal.service';
import { BuilderProject, BuilderTemplate } from '../../shared/builder-types';
import { CrupdateProjectModalComponent } from '../../shared/crupdate-project-modal/crupdate-project-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { PaginationResponse } from '@common/core/types/pagination/pagination-response';
import { InfiniteScroll } from '@common/core/ui/infinite-scroll/infinite.scroll';
import { Templates } from '../../shared/templates/templates.service';
import { DomHelpers } from '../../shared/dom-helpers.service';
import { PageDocument } from '../../shared/page-document';
import { Projects } from '../../shared/projects/projects.service';
import { Project } from '../../shared/projects/Project';
import { randomString } from '@common/core/utils/random-string';
import { finalize } from 'rxjs/operators';
import { Toast } from 'common/core/ui/toast.service';
import { slugifyString } from '@common/core/utils/slugify-string';
import { CurrentUser } from '@common/auth/current-user';

interface FilterFormValue {
    project?: Project;
    search: string;
    category: string;
}
interface LocationForm {
    zipcode: string;
    country: string;
    range: string;
    template: string;
    state: string;
}
interface detailsForm {
    name: string;
    company: string;
    email: string;
    mainService: string;
}
interface TemplateForm {
    file: File;
}


@Component({
    selector: 'new-project-page',
    templateUrl: './new-project-page.component.html',
    styleUrls: ['./new-project-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewProjectPageComponent extends InfiniteScroll implements OnInit {
    public allCategories: string[] = [];
    public templatePagination: PaginationResponse<BuilderTemplate>;
    public filteredTemplates: BuilderTemplate[] = [];
    public loading = false;
    public isInprocess = false;
    public selectedTemplate;
    public errors: any = [];
    public estimate: any = [];
    public projectDetails: any = [];
    public locations: any = [];
    public showtStates = false;
    public showNext = false;
    private pageDocument = new PageDocument();

    /**
      * Template file input value.
      */
    public files: { thumbnail?: File, template?: File } = {};

    public filterForm = new FormGroup({
        search: new FormControl(),
        category: new FormControl(null),
    });
    public locationForm = new FormGroup({
        zipcode: new FormControl(null),
        country: new FormControl("AUS"),
        range: new FormControl(null),
        template: new FormControl(null),
        state: new FormControl(null),
    });
    public detailsForm = new FormGroup({
        name: new FormControl(null),
        company: new FormControl(null),
        email: new FormControl(null),
        mainService: new FormControl(null),
    });
    public TemplateForm = new FormGroup({
        file: new FormControl(null),
    });
    public paymentForm = new FormGroup({
        nameoncard: new FormControl(),
        ccnumber: new FormControl(),
        expiryM: new FormControl(),
        expiryY: new FormControl(),
        cvc: new FormControl(),
    });


    constructor(
        private route: ActivatedRoute,
        private settings: Settings,
        private modal: Modal,
        private router: Router,
        protected zone: NgZone,
        protected templates: Templates,
        public projects: Projects,
        private toast: Toast,
        public currentUser: CurrentUser,
    ) {
        super();
    }

    ngOnInit() {
        this.allCategories = this.settings.getJson('builder.template_categories', []);

        this.locationForm.valueChanges.subscribe((value: LocationForm) => {
            this.showtStates = value.country == 'US' ? true : false;
            if (value.zipcode && value.zipcode.length > 2) {
                this.getRelatedLocations(value)
            }
        });
        this.detailsForm.valueChanges.subscribe((value: detailsForm) => {
            this.showNext = value.name !== '' ? true : false;
        });
        this.filterForm.valueChanges.subscribe((value: FilterFormValue) => {
            this.applyFilters(value);
        });

        this.route.data.subscribe(resolve => {
            this.templatePagination = resolve.templates;
            this.filteredTemplates = resolve.templates.data;
        });

        super.ngOnInit();
    }

    public openNewProjectModal(templateName?: string, location?: [], range?: []) {
        // let locations = estimatePayload;
        //console.log(location)
        this.modal.open(CrupdateProjectModalComponent, { templateName, location, range })
            .afterClosed().subscribe((project: BuilderProject) => {
                if (!project) return;
                this.router.navigate(['/builder', project.model.id]);
            });
    }
    public selectTemplate(templateName?: string) {
        // this.locationForm.setValue(...{template: templateName });
        this.selectedTemplate = templateName;
        //this.createProject();
        //this.locationForm.value.template = templateName;
    }
    public isSelected(templateName?: string) {
        return this.selectedTemplate == templateName;
    }
    public saveProjectDetails() {
        if (this.detailsForm.value.name == null || this.detailsForm.value.name == '') {
            this.errors = ["Project name can't be empty!"];
            return false;
        }
        this.errors = null;
        this.projectDetails = this.detailsForm.value;
        return true;
    }
    public async createProject() {
        this.isInprocess = true;
        let project = new Project({
            pages: [],
            template: null,
            framework: null,
            uuid: randomString(36),
            users: [],
        })
        // if (this.estimate == null) {
        //     this.errors = ["Something wrong with your location details, Please check and start over again!"];
        //     return false;
        // }
        this.errors = null;
        let request;

        const payload = await this.getNewProjectPayload(project);

        payload.slug = slugifyString(this.detailsForm.value.name+'-'+randomString(6));
        if (!payload?.name){
            //payload.concat(this.detailsForm.value);
            payload.name = this.detailsForm.value.name;
            payload.mainService = this.detailsForm.value.mainService;
            // payload.company = this.detailsForm.value.company;
            // payload.email = this.detailsForm.value.email;
        }
        request = this.projects.create(payload);
        request.subscribe(response => {
            //this.close(response.project);
            this.toast.open('Project has been created');
            this.isInprocess = false;
            this.router.navigate(['/builder', response.project.model.id]);
        }, response => {
            this.errors = response.messages;
            this.isInprocess = false;
        });

    }
    private getPayload(extra_data) {
        const data = new FormData();

        // append template and thumbnail files
        if (this.files.template) data.append('custom_template', this.files.template);
        // append model values
        for (const name in this.projectDetails) {
            data.append(name, this.projectDetails[name]);
        }
        for (const ext_d in extra_data) {
            data.append(ext_d, extra_data[ext_d]);
        }

        return data;
    }
    /**
    * Set template file and clear errors.
    */
    public setFile(type: 'template' | 'thumbnail', files: FileList) {
        this.files[type] = files.item(0);
        this.selectedTemplate = 'custom';
        this.errors = null;
    }
    private getNewProjectPayload(project) {
        const templateName = this.selectedTemplate;
        if (templateName != 'custom') {
            return this.createProjectFromTemplate(templateName, project);
        } else {
            //let customPayload = this.createBlankProject();
            // custom html files
            // this.getPayload(customPayload);
            // customPayload.template = this.files;
            return this.getPayload(this.createBlankProject(project));
        }
    }

    private createProjectFromTemplate(templateName: string, project: Project): Promise<any> {
        return new Promise(resolve => {
            const params = project as any;
            this.templates.get(templateName).subscribe(response => {
                params.template = response.template;
                params.framework = response.template.config.framework;
                params.pages = this.transformTemplatePages(response.template);
                resolve(params);
            });
        });
    }

    private createBlankProject(project) {
        const params = project as any;

        params.pages.push({
            name: 'index',
            html: DomHelpers.removeBaseUrl(this.pageDocument.generate().getOuterHtml()),
        });

        return params;
    }

    private transformTemplatePages(template: BuilderTemplate) {
        return template.pages.map(page => {
            return {
                name: page.name,
                html: DomHelpers.removeBaseUrl(this.pageDocument.generate(page.html, template).getOuterHtml()),
            };
        });
    }
    public getRelatedLocations(param?: object) {
        //this.loading = true;
        this.projects.getAutoLocations(param)
            .pipe(finalize(() => this.loading = false))
            .subscribe(response => {
                this.locations = response.locations;
            }, response => {
                if (response.messages) {
                    this.errors = Object.keys(response.messages).map(key => (response.messages[key]))
                    //console.log(this.errors)
                }
            });

    }
    public searchLocationsEstimate(templateName?: string) {
        this.errors = null;
        this.isInprocess = true;
        //this.loading = true;
        //console.log(this.locationForm.value)
        this.estimate = [];
        this.locationForm.value.template = templateName;
        let request;
        request = this.projects.estimate(this.locationForm.value)
            .pipe(finalize(() => this.isInprocess = false))
            .subscribe(response => {
                this.estimate = response;
            }, response => {
                if (response.messages) {
                    this.errors = Object.keys(response.messages).map(key => (response.messages[key]))
                }
            });

    }

    public getTemplateThumbnail(template: BuilderTemplate) {
        return this.settings.getBaseUrl(true) + template.thumbnail;
    }

    public applyFilters(value: FilterFormValue) {
        this.filteredTemplates = this.templatePagination.data.filter(template => {
            const templateName = template.name || template.config.name || '';
            const matchesCategory = !value.category || template.config.category === value.category;
            const matchesSearch = !value.search || templateName.toLowerCase().indexOf(value.search.toLowerCase()) > -1;
            return matchesCategory && matchesSearch;
        });
    }

    protected canLoadMore(): boolean {
        return this.templatePagination.current_page < this.templatePagination.last_page;
    }

    protected isLoading(): boolean {
        return this.loading;
    }

    protected loadMoreItems() {
        this.loading = true;
        this.templates.all({ page: this.templatePagination.current_page + 1, per_page: 25 })
            .pipe(finalize(() => this.loading = false))
            .subscribe(response => {
                const data = [
                    ...this.templatePagination.data,
                    ...response.pagination.data,
                ];
                this.templatePagination = { ...response.pagination, data };
                this.filteredTemplates = data;
            });
    }
}
