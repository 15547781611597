<ng-container *ngIf="currentUser.isLoggedIn$ | async; else guestMenuItems">
    <a class="admin-link" mat-menu-item routerLink="/admin" *ngIf="currentUser.hasPermission('admin')">
        <mat-icon class="mat-icon" svgIcon="dashboard"></mat-icon>
        <span trans>Admin Area</span>
    </a>
    <a class="admin-link" mat-menu-item routerLink="/admin/users" *ngIf="currentUser.hasPermission('users.view') && !currentUser.hasPermission('admin')">
        <mat-icon class="mat-icon" svgIcon="people"></mat-icon>
        <span trans>Users</span>
    </a>

    <!-- <a class="notifications-link" mat-menu-item routerLink="/billing/upgrade" *ngIf="forceTryProButton && config.get('billing.enable') && !currentUser.isSubscribed()">
        <mat-icon svgIcon="star"></mat-icon>
        <span trans>Try Pro</span>
    </a> -->

    <a class="notifications-link" mat-menu-item routerLink="/notifications" *ngIf="forceNotifButton || ((breakpoints.isMobile$ | async) && config.get('notifications.integrated'))">
        <mat-icon svgIcon="notifications"></mat-icon>
        <span trans>Notifications</span>
    </a>

    <ng-content></ng-content>

    <ng-container *ngIf="!hideConfigItems">
        <ng-container *ngFor="let item of config.get('vebto.navbar.dropdownItems')">
            <a mat-menu-item [routerLink]="item.route" *ngIf="shouldShowMenuItem(item)">
                <mat-icon [svgIcon]="item.icon"></mat-icon>
                <span trans>{{item.name}}</span>
            </a>
        </ng-container>
    </ng-container>

    <a class="account-settings-link" mat-menu-item routerLink="/account/settings">
        <mat-icon class="mat-icon" svgIcon="settings"></mat-icon>
        <span trans>Account Settings</span>
    </a>

    <!-- <ng-container  *ngIf="currentUser.isSubscribed()">
        <a class="subscription-link" mat-menu-item routerLink="/billing/subscription">
            <mat-icon class="mat-icon" svgIcon="credit-card"></mat-icon>
            <span trans>Subscription</span>
        </a>

        <a class="subscription-link" mat-menu-item routerLink="/billing/invoices">
            <mat-icon class="mat-icon" svgIcon="assignment"></mat-icon>
            <span trans>Invoices</span>
        </a>
    </ng-container> -->

    <div mat-menu-item (click)="theme.toggle()" *ngIf="config.get('themes.user_change')">
        <ng-container *ngIf="(theme.selectedTheme$ | async)?.is_dark; else lightMode">
            <mat-icon svgIcon="light-on"></mat-icon>
            <span trans>Light Mode</span>
        </ng-container>
        <ng-template #lightMode>
            <mat-icon svgIcon="light"></mat-icon>
            <span trans>Dark Mode</span>
        </ng-template>
    </div>

    <a class="account-settings-link" mat-menu-item target="_blank" href="https://jivo.chat/pWrtxCJFb6">
        <mat-icon class="mat-icon" svgIcon="info"></mat-icon>
        <span trans>Support</span>
    </a>
    <a class="account-settings-link" mat-menu-item target="_blank" href="https://www.youtube.com/playlist?list=PLnAVSGs6mdcukq5U7hhrO-0OOXtEslz3L">
        <mat-icon class="mat-icon" svgIcon="video-library"></mat-icon>
        <span trans>Training videos</span>
    </a>
    <div class="logout-item" mat-menu-item (click)="auth.logOut()">
        <mat-icon svgIcon="exit-to-app"></mat-icon>
        <span trans>Log Out</span>
    </div>
</ng-container>

<ng-template #guestMenuItems>
    <a routerLink="/login" mat-menu-item trans>Login</a>
    <a routerLink="/register" mat-menu-item *ngIf="!config.get('registration.disable') && !hideRegisterButton" trans>Register</a>
</ng-template>
