<material-navbar menuPosition="dashboard" [container]="true"></material-navbar>

<div class="content">
    <ad-host slot="ads.dashboard_top" class="ad-host-top"></ad-host>

    <div class="container">

        <header class="page-header">
            <h1 class="title" trans>2 Simple steps to get started</h1>
            <!-- <p class="subtitle" trans>Choose a template or upload your own, or grab any
                online website to get started.</p> -->
        </header>
        <div class="error" *ngIf="errors">
            <ul *ngIf="!isInprocess">
                <li *ngFor="let err of errors">{{err}}</li>
            </ul>
        </div>
        <mat-horizontal-stepper [linear]="false" #stepper>
            <mat-step label="Project Details">
                <div class="project_content">
                    <div>
                        <h2>Choose a unique project name, main service and other
                            information</h2>
                    </div>
                    <div class="pro_detail_form" [formGroup]="detailsForm">
                        <div class="input-container">
                            <label for="name" class="hidden" trans>Project
                                Name *</label>
                            <input type="text" id="name" formControlName="name"
                                placeholder="Project Topic *" trans-placeholder required>
                        </div>
                        <div class="input-container">
                            <label for="mainService" class="hidden" trans>Mail
                                Service</label>
                            <input type="text" id="mainService"
                                formControlName="mainService"
                                placeholder="Main Service, e.g. web design"
                                trans-placeholder>
                        </div>
                    </div>
                </div>
                <div class="wizard_navigation">
                    <div class="input-container pull-right">
                        <button mat-button mat-raised-button color="accent"
                            [disabled]="!showNext"
                            (click)="saveProjectDetails() ? stepper.next() : null">Next:
                            Choose Template</button>
                    </div>
                </div>

            </mat-step>
            <mat-step label="Choose Template">
                <mat-tab-group color="accent" animationDuration="0">
                    <mat-tab [label]="'Templates' | trans">
                        <!-- <div class="input-container category-input-container">
                            <label for="category-input" class="hidden" trans>Category</label>
                            <select id="category-input" formControlName="category">
                              <option [ngValue]="null" trans>All Categories</option>
                              <option [value]="category" *ngFor="let category of allCategories">
                                {{ category }}
                              </option>
                            </select>
                          </div> -->
                        <div class="templates template-grid tab-content">
                            <div class="template-grid">
                                <figure class="template-grid-item"
                                    [ngClass]="selectedTemplate == template.name  ? 'activeTemp' : 'inactiveTemp'"
                                    *ngFor="let template of filteredTemplates">
                                    <img [src]="getTemplateThumbnail(template)">
                                    <figcaption class="template-grid-item-footer">
                                        <p>{{template?.config?.display_name || template.name}}
                                        </p>
                                        <div class="meta-actions">
                                            <a [href]="getTemplateThumbnail(template)"
                                                target="_blank" mat-button
                                                mat-raised-button>Preview</a>
                                            <button mat-button mat-raised-button
                                                color="accent"
                                                (click)="selectTemplate(template.name)">{{selectedTemplate == template.name ? "Selected":'Use Template'}}</button>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'Upload Your Own' | trans"
                        *ngIf="currentUser.hasPermission('templates.upload')">
                        <div class="custom-templ tab-content">
                            <p>Upload your own HTML template instead for template.</p>

                            <div class="input-container file-input-container">
                                <label for="templateInput" class="hidden" trans>Template
                                    Zip
                                    File</label>
                                <input type="file" #templateInput
                                    (change)="setFile('template', templateInput.files)"
                                    accept="application/zip" />
                                <p trans>Zip of template files. Must contain index.html
                                    file
                                    which will be used as default page.</p>
                                <p trans>(Optional) CSS and JS that should appear in
                                    builder
                                    code editors should be in "css/styles.css" and
                                    "js/scripts.js" files.</p>
                            </div>



                        </div>
                    </mat-tab>
                    <!-- <mat-tab [label]="'Grab Live' | trans">
                    <div class="live-download template-grid tab-content">
    
                    </div>
                </mat-tab> -->
                </mat-tab-group>
                <!-- <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="stepper.reset()">Reset</button>
                </div> -->
                <div class="inidicator_space">
                    <loading-indicator [isVisible]="isInprocess" class="inline">
                    </loading-indicator>
                </div>
                <div class="wizard_navigation fixed_position">
                    <div class="input-container category-input-container">
                        <button mat-button mat-raised-button matStepperPrevious>Back:
                            Project Details</button>
                    </div>
                    <div class="input-container pull-right">
                        <button mat-button mat-raised-button color="accent"
                            [disabled]="!selectedTemplate" (click)="createProject()"
                            matStepperNext>Finish: Create Project</button>
                    </div>
                </div>
            </mat-step>

        </mat-horizontal-stepper>


    </div>

    <ad-host slot="ads.dashboard_bottom" class="ad-host-bottom"></ad-host>
</div>

<loading-indicator [isVisible]="loading" class="inline"></loading-indicator>
