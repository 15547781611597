import { Injectable } from '@angular/core';
import { AppHttpClient } from 'common/core/http/app-http-client.service';
import { BuilderProject, FtpDetails } from '../builder-types';
import { Project } from './Project';
import { PaginatedBackendResponse } from '@common/core/types/pagination/paginated-backend-response';
import { BackendResponse } from '@common/core/types/backend-response';

@Injectable({
    providedIn: 'root'
})
export class Projects {
    constructor(private http: AppHttpClient) { }

    /**
     * Get all available projects.
     */
    public all(params?: { user_id?: number, per_page?: number }): PaginatedBackendResponse<Project> {
        return this.http.get('projects', params);
    }

    /**
     * Get project matching specified id.
     */
    public get(id: number): BackendResponse<{ project: BuilderProject }> {
        return this.http.get('projects/' + id);
    }

    /**
     * Create a new project.
     */
    public create(params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects', params);
    }

    /**
     * Update project matching specified id.
     */
    public update(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.put('projects/' + id, params);
    }

    /**
     * Delete project matching specified id.
     */
    public delete(params: { ids: number[] }): BackendResponse<void> {
        return this.http.delete('projects', params);
    }
    /**
     * Clone existing project.
     */
    public clone(params: { id: number }): BackendResponse<{ project: Project }> {
        return this.http.post('projects/clone', params);
    }

    /**
     * Create or update specified project's thumbnail image.
     */
    public generateThumbnail(projectId: number, dataUrl: string): BackendResponse<void> {
        return this.http.post('projects/' + projectId + '/generate-thumbnail', { dataUrl });
    }

    /**
     * Publish specified project to FTP.
     */
    public publish(projectId: number, params: FtpDetails): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + projectId + '/publish', params);
    }
    /**
     * Estimate the locations.
     */
    public estimate(params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/estimate', params);
    }
    /**
     * Get Autocomplete location list.
     */
    public getAutoLocations(params: object): BackendResponse<{ locations: BuilderProject }> {
        return this.http.post('projects/autolocations', params);
    }
    /**
     * Save project services.
     */
    public updateservices(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/saveservices', params);
    }
    /**
     * Get download file.
     */
    public getProjectZip(id: number, params: object): BackendResponse<{ file: any }> {
        return this.http.post('projects/' + id + '/file', params);
    }

    /**
     * Change project location.
     */
    public updatelocation(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/updatelocation', params);
    }
    /**
     * Save project services.
     */
    public updateCodes(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/savecodes', params);
    }
    /**
     * Save project variables.
     */
    public updatevariable(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/variable', params);
    }
    /**
     * Save project bulk variables.
     */
    public addbulkvariables(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/bulk-variables', params);
    }
    /**
     * Delete project variables.
     */
    public deletevariable(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/delvariable', params);
    }
    /**
     * Save project services.
     */
    public delLocation(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post('projects/' + id + '/deletelocation', params);
    }
    /**
     * Save project services.
     */
    public uploadCSV(id: number, params: any): BackendResponse<{ data: BuilderProject }> {
        return this.http.post('projects/' + id + '/import-variables', params);
    }
}
